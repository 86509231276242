<template>
	<list-template
      :loading="loading"
		:table-config="tableConfig"
		:table-data="tableData"
		:total="total"
		:current-page="page"
		has-add
		@onAdd="$router.push('./add')"
		@onChangePage="changeCurrentPage"
		@onHandle="tableHandle"
	></list-template>
</template>

<script>
//引入ajax接口管理层的接口函数

import { getBuildingslist, delBuildings } from '@/api/building'
import { mapState } from 'vuex'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  computed: {
		...mapState(['page'])
	},
	methods: {
		// 分页被更改
		changeCurrentPage(e) {
			this.$store.commit('setPage', e)
			this.getData()
		},
		//
		tableHandle(row, handle) {
      const { type } = handle
      if(type === 'delete') {
        this.$confirm('删除后会影响其他功能使用，是否删除', {
          confirmButtonText: '删除',
          cancelButtonText: '点错了',
          type: 'warning'
        })
          .then(async () => {
            let res = await delBuildings(row.id)
            await this.getData()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
          .catch(async () => {})
      } else if(type === 'edit') {
        this.$router.push({ path: './edit', query: { id: row.id } })
      }
		},
		async getData() {
			let page = this.page
      this.loading=true;
			let res = await getBuildingslist(page).finally(()=>this.loading = false);
			this.tableData = res.data.data

			let { headers } = res
			let date = this.$tools.getPaginationInfo(headers)
			this.total = date.total
		},
		handleClick(row) {},

		//  点击新增跳到添加页面。
		linkAdd() {
			this.$router.push('./add')
		},
		//  点击编辑跳到编辑页面并把row这一行的数据作为参数传递。
		handleEdit(row) {
			this.$router.push({ path: './edit', query: { id: row.id } })
		},

		// 删除数据
		async handleDelete(row) {
			this.$confirm('删除后会影响其他功能使用，是否删除', {
				confirmButtonText: '删除',
				cancelButtonText: '点错了',
				type: 'warning'
			}).then(async () => {
				let res = await delBuildings(row.id)
				await this.getData()
				this.$message({
					type: 'success',
					message: '删除成功'
				})
			})

			// .catch(() => {
			// 	this.$message({
			// 		type: 'error',
			// 		message: '取消删除'

			// 	})

			// })
		},

		// 当前页改变触发
		handleCurrentChange(val) {
			this.$store.commit('setPage', val)
			//拉取数据
			this.getData()
		}
	},

	data() {
		return {
      loading:true,
			tableData: [
				{
					name: '',
					usage: '',
					floor_min: '',
					floor_max: '',
					school_name: ''
				}
			],
			total: 0,
			tableConfig: [
				{
					prop: 'dimicile_buliding_name',
					label: '楼宇名称'
				},
				{
					prop: 'school_name',
					label: '所属校区'
				},
				{
					prop: 'usage',
					label: '楼宇用途'
				},
				{
					prop: 'floor_min',
					label: '最低楼层'
				},
				{
					prop: 'floor_max',
					label: '最高楼层'
				},
				{
					handle: true,
					width: 120,
					label: '操作',
					buttons: [{ type:"delete", text:"删除" }]
				}
			]
		}
	},
	activated() {
		this.getData()
	},
	created() {
		this.$store.commit('setPage', 1)
		this.getData()
		// this.getlist()
	}
}
</script>

<style lang="scss" scoped></style>
